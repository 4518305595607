import { Box } from "@mui/material";
import { UnderConstructionPage } from "../Pages/UnderConstructionPage";

const App = () => {
  return (
    <Box sx={{ m: 0, p: 0 }}>
      <UnderConstructionPage />
    </Box>
  );
};

export default App;
