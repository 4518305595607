import { Box, Typography } from "@mui/material";
import { FC } from "react";

export interface UnderConstructionPageProps {}

export const UnderConstructionPage: FC<UnderConstructionPageProps> = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#000",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ backgroundColor: "#000", flex: 1, maxWidth: 650, px: 4 }}>
        <img
          src={"/NousDeuxFashion_white.svg"}
          alt={"logo Nous Deux Fashion"}
        />
        <Typography
          sx={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "light",
            textAlign: "center",
            mt: 1,
          }}
        >
          Our website is under construction.
        </Typography>
      </Box>
    </Box>
  );
};
